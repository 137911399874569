import React, { useState } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LoginPage.css';
import Footer from './Footer';

// Export the handleGoogleSignIn function
export const handleGoogleSignIn = async (navigate, setLoading, setWasAnonymous = null) => {
  setLoading(true); // Set loading to true when sign-in begins
  try {
    console.log('Attempting Google Sign-In'); // Debugging message

    // Sign in with Google
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    console.log('Google Sign-In result:', result); 
    console.log('Signed in user:', user);

    // Get the token asynchronously
    const token = await user.getIdToken();
    console.log('Firebase token:', token);

    let databaseUid; // To store the database UID returned from the server
    let credits;
    let firebaseuid;
    let isNewUser;

    const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' :    'https://flask-backend-52245432644.us-central1.run.app';
    const response = await axios.post(`${baseURL}/login-user`, { token });
    
    // Get the database UID from the response
    databaseUid = response.data.database_uid;
    credits = response.data.credits;
    firebaseuid=response.data.firebaseuid;
    isNewUser = response.data.isNewUser;
    console.log('credits: ', credits);
    console.log('firebase-uid: ', firebaseuid);
    if (setWasAnonymous) {
      setWasAnonymous(false);
    }

    // Store the user information
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('databaseUid', databaseUid);
    localStorage.setItem('uid', databaseUid); // Store UID
    localStorage.setItem('firebaseuid',firebaseuid);
    localStorage.setItem('credits', credits);

    console.log('User logged in successfully. Redirecting to dashboard.');

    const params = new URLSearchParams(window.location.search);
    const pid = params.get('pid');

    if (pid) {
      // Redirect to the report link if pid exists
      navigate(`/dashboard?pid=${pid}`);
    } else if (isNewUser) {
      // Redirect to Analyze page for new users
      navigate('/dashboard');
    } else {
      // Redirect to Screeners page for returning users
      navigate('/dashboard/screeners');
    }
  } catch (error) {
    console.error('Error during Google Sign-In:', error);
    alert(error.message);
  } finally {
    setLoading(false); // Set loading to false once sign-in completes
  }
};

// The rest of the LoginPage component
const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [wasAnonymous, setWasAnonymous] = useState(false);

  const signIn = () => {
    handleGoogleSignIn(navigate, setLoading, setWasAnonymous);
  };

  return (
    <div className="login-page">
      <div className="login-card">
        <div className="login-header">
          <h1 className="login-title">MarketCrunch AI&#8482;</h1>
          <p className="login-subtitle">Free AI-driven Research and Prediction. <br/> No credit card needed.</p>
        </div>

        {/* Google Sign-In Button */}
        {!loading ? (
          <button className="login-button" onClick={signIn}>
            <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google Icon" className="google-icon"/>
            Continue with Google
          </button>
        ) : (
          <p>Loading...</p> // Loading indicator while sign-in is in progress
        )}

        {wasAnonymous && (
          <div className="anon-indicator">
            <p>You were previously an anonymous user. Your data has been updated.</p>
          </div>
        )}
      </div>
    </div>
  );
};
<Footer />

export default LoginPage;
