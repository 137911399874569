import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

const stripe_pk = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripe_pk);

const PaymentPage = () => {
  const [user, setUser] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pid = query.get('pid');
  const isRecurring = query.get('isRecurring');
  const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'https://4814-2620-0-1a10-7821-2144-40f5-e45e-682d.ngrok-free.app' : 'https://flask-backend-52245432644.us-central1.run.app';

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
        } else {
            setUser(null); 
        }
        });
    return () => unsubscribe();
}, [user]);

const fetchClientSecret = useCallback(async () => {
  if (!user) return; // Ensure user is defined before making the API call

  const database_uid = localStorage.getItem('databaseUid');

  try {
      let credits_received;

      switch(pid) {
        case 'RYFobYWGMdJGGz':
        case 'R57yhIPFC7GbGB':
        case 'RAZB9vQMpvfQSd':
        case 'RAZMTyeZ0I3liW':
          credits_received = 3;
          break;
        case 'RIXHjuTgN2gSOD':
        case 'RAZQ0dgxsb5ka0':
          credits_received = 10;
          break;   
        case 'RIXImK7AIvEb2i':
        case 'RAZRg4rI31zjTd':
          credits_received = 21;
          break;   
        default:
          console.error("Invalid pid");
          break;    
      }
      const response = await fetch(`${baseURL}/create-checkout-session?pid=${pid}&uid=${database_uid}&is_recurring=${isRecurring}&credits_received=${credits_received}`, {
          method: "POST",
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
  } catch (error) {
      console.error("fetchClientSecret failed:", error);
      throw new Error("Failed to fetch client secret.");
  }
}, [baseURL, pid, user]);

// Call fetchClientSecret only when user is defined
useEffect(() => {
  if (user) {
      fetchClientSecret()
          .catch((error) => {
              console.error("Error fetching client secret:", error);
          });
  }
}, [user, fetchClientSecret]);

  const options = { clientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default PaymentPage;
