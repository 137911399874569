import React from 'react';
import './TradingSummarySection.css';
import tradingImg1 from '../assets/images/trading-img1.png'; // Adjust the path as needed
import tradingImg2 from '../assets/images/trading-img2.png'; // Adjust the path as needed

const TradingSummarySection = () => {
  return (
    <div id="home" className="trading-summary-section">
      {/* Top Row: Text */}
      <div className="trading-text-row">
        <h3>Focus on signals that matter</h3>
      </div>

      {/* Bottom Row: Images */}
      <div className="trading-images-row">
        <div className="trading-column">
          <img src={tradingImg1} alt="Trading Image 1" className="trading-img" />
        </div>
        <div className="trading-column">
          <img src={tradingImg2} alt="Trading Image 2" className="trading-img" />
        </div>
      </div>
    </div>
  );
};

export default TradingSummarySection;
