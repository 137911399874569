import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'; // Only import FaBars here
import SearchBox from './SearchBox';
import AimSection from './AimSection';
import InsightsSection from './InsightsSection';
import TradingSummarySection from './TradingSummarySection';
import ModelSection from './ModelSection';
import TestimonialSection from './TestimonialSection';
import TeamSection from './TeamSection';
import RedditSection from './RedditSection';
// import BlogSection from './BlogSection';
import Footer from './Footer';
import MobileMenu from './MobileMenu'; // Import the new MobileMenu component
import './LandingPage.css';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { logUserActivity } from './logActivity';

// Import Swiper and its required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const LandingPage = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [ticker, setTicker] = useState(''); // Store the ticker input
  const [user, setUser] = useState(null); // Store current user
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu state
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTicker = params.get('ticker');

  const handleRedirect = () => {
    user ? navigate('/dashboard') : navigate('/login');
  };

  useEffect(() => {
    if (urlTicker) {
      handleFetchData(urlTicker.toUpperCase());
    }
  }, [urlTicker]);

  const handleFetchData = async (ticker) => {
    if (!ticker) {
      alert('Please enter a ticker symbol');
      return;
    }

    // Navigate to the progress page and pass the ticker before making the API call
    navigate('/progress', { state: { ticker } });
  };

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Check if user is logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let userId = localStorage.getItem('uid'); // Get user ID from localStorage
    if (!userId) {
      userId = '0'; // Default to 0 if no UID is found
    }
    logUserActivity(userId, 'HME'); // Use 'HME' for the LandingPage
    console.log(`User activity logged for page type: HME with uid: ${userId}`);
  }, []); // This runs only once when the component is mounted

  return (
    <div className="landing-page">
      <div className="product-page" id="product-page">
        {/* Header */}
        <div className="header">
          {/* Logo */}
          <h3 style={{ display: 'inline-block', position: 'relative' }}>
            <a
              href="#top"
              onClick={() =>
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }
            >
              MarketCrunch AI
              <span
                style={{
                  fontSize: '0.75em',
                  position: 'relative',
                  top: '-0.2em',
                }}
              >
                &#8482;
              </span>
              <span
                style={{
                  display: 'block',
                  fontSize: '0.5em',
                  position: 'absolute',
                  left: '75%',
                  top: '2.3em',
                  fontStyle: 'italic',
                  letterSpacing: '0.05em',
                }}
              >
                BETA
              </span>
            </a>
          </h3>

          {/* Navigation Links for Desktop */}
          <div className="tabs-button-wrapper">
            <div className="tabs" style={{ marginBottom: 0 }}>
              <a href="#home">Product</a>
              <a href="#testimonials">Testimonials</a>
              <a href="#team">Team</a>
            </div>
          </div>

          {/* Header Right: Sign-in Button and Hamburger Menu */}
          <div className="header-right">
            <button className="signin-button" onClick={handleRedirect}>
              {user ? 'Dashboard' : 'Free Sign Up'}
            </button>
            <div
              className="menu-icon"
              onClick={toggleMenu}
              aria-label="Toggle navigation menu"
            >
              <FaBars />
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

        {/* Background Section */}
        <div id="background"></div>

        {/* Product Section */}
        <div id="product" className="content">
          <div className="search-row">
            <div className="left-column">
              <h1 className="hidden-heading">
                MarketCrunch AI - AI-Based Investment Research
              </h1>
              <h2 className="hidden-heading">
                AI Stock Predictions and Insights
              </h2>
              <h3>Get AI-based Prediction</h3>
              <p>
                Our advanced AI analyzes complex data from fundamentals,
                technicals, and other sources to give you real, detailed, and
                impactful insights. <br />
                <br />
                No hallucinations.
              </p>
            </div>

            <div className="right-column">
              <div className="search-box-wrapper">
                <SearchBox onSearch={handleFetchData} loading={loading} />
              </div>
              <p>Tracking 38,000+ stocks, ETFs, MFs in US </p>
            </div>
          </div>

          <p>{statusMessage}</p>
        </div>
      </div>

      {/* Swiper Carousel */}
      <div className="carousel-container">
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 7000 }}
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
        >
          <SwiperSlide>
            <AimSection />
          </SwiperSlide>
          <SwiperSlide>
            <InsightsSection />
            </SwiperSlide>
            {/* <SwiperSlide>
              <RedditSection />
            </SwiperSlide> */}

          <SwiperSlide>
            <TradingSummarySection />
          </SwiperSlide>
          <SwiperSlide>
            <ModelSection />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Other Sections */}
      <TestimonialSection />
      <TeamSection />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
