import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UpgradePopup.css';

const UpgradePopup = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();
  const [isWeekly, setIsWeekly] = useState(true); // State for billing cycle toggle
  const [selectedPlan, setSelectedPlan] = useState(1); // State for selected plan

  if (!isOpen) {
    return null; // Do not render if the popup is closed
  }

  // Toggle between monthly and yearly pricing
  const handleBillingToggle = () => {
    setIsWeekly((prev) => !prev);
  };

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle confirmation
  const handleConfirm = () => {
    let pid;
    if (selectedPlan === 0) {
      // navigate(`/payment/?pid=RAZMTyeZ0I3liW`);
      isWeekly
        ? process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? pid = 'RYFobYWGMdJGGz' : pid = 'R57yhIPFC7GbGB'
        : process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? pid = 'RAZB9vQMpvfQSd' : pid = 'RAZMTyeZ0I3liW';
      // isWeekly ? pid = 'R58LQs8yGikFvT' : pid = 'RAZB9vQMpvfQSd'; // This is for testing
    }
    else if (selectedPlan === 1) {
      // navigate(`/payment/?pid=RAZQ0dgxsb5ka0`);
      isWeekly ? pid = 'RIXHjuTgN2gSOD' : pid = 'RAZQ0dgxsb5ka0';
    }
    else if (selectedPlan === 2) {
      // navigate(`/payment/?pid=RAZRg4rI31zjTd`);
      isWeekly ? pid = 'RIXImK7AIvEb2i' : pid = 'RAZRg4rI31zjTd';
    }
    navigate(`/payment?pid=${pid}&isRecurring=${isWeekly}`)
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>Try Advanced AI Analysis with a FREE Trial!</h2>
          <p className="popup-header-text">
          Thank you for using the free credits - our way to make AI-analysis accessible! Enjoy continued access with no-risk, free-trial (seriously!). Paid credits don't expire.</p>
          <br/>
          <p id="popup-header-text"><b>No commitment. Cancel anytime.</b></p>
          <button className="close-button" onClick={handleClose}>✕</button>
        </div>

        <div className="plan-options">
          <button
            className={`plan basic ${selectedPlan === 0 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(0)}
          >
            <h3>{isWeekly ? '$3.99' : '$4.99'}</h3>
            {/* <p>{isWeekly ? 'Billed Weekly' : 'One Time'}</p> */}
            {/* <h3>$4.99</h3> */}
            <ul>
              <li>✔ If you trade 1x/week</li>
              <li>{isWeekly ? <span>✔ <span className={`${selectedPlan === 0 ? 'bold' : ''}`}>Save 20%</span> w/ subscription</span> : '✔ Easiest to Start'}</li>
              <li>✔ {isWeekly ? <span className={`${selectedPlan === 0 ? 'bold' : ''}`}>3 credits/week</span> : <span className={`${selectedPlan === 0 ? 'bold' : ''}`}>3 credits</span> }</li>
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 1 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(1)}
          >
            { isWeekly ? <div className="upgrade-popup-ribbon">Free 3-Day Trial!</div> : ''}
            <h3>{isWeekly ? <>$8.99</> : <>$11.99</>}</h3>
            {/*<p>{isWeekly ? 'Billed Weekly' : 'One Time'}</p>*/}
            {/* <h3>$11.99</h3> */}
            <ul>
              <li>✔ If you trade 3-5x/week</li>
              <li>{isWeekly ? <span>✔ <span className={`${selectedPlan === 1 ? 'bold' : ''}`}>Save 25%</span> w/ subscription</span> : <span>✔ <span className={`${selectedPlan === 1 ? 'bold' : ''}`}>Buy more, Save 28%</span></span>}</li>
              <li>✔ {isWeekly ? <span className={`${selectedPlan === 1 ? 'bold' : ''}`}>10 credits/week</span> : <span className={`${selectedPlan === 1 ? 'bold' : ''}`}>10 credits</span> }</li>
              {/* isWeekly ? <li>✔ <span className={`${selectedPlan === 1 ? 'bold' : ''}`}>3 days free!</span></li> : '' */}
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 2 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(2)}
          >
            { isWeekly ? <div className="upgrade-popup-ribbon">Free 7-Day Trial</div> : ''}
            <h3>{isWeekly ? <>$12.99</> : <>$18.99</>}</h3>
            {/*<p>{isWeekly ? 'Billed Weekly' : 'One Time'}</p>*/}
            {/* <h3>$18.99</h3> */}
            <ul>
              <li>✔ Best for Daily Traders</li>
              <li>{isWeekly ? <span>✔ <span className={`${selectedPlan === 2 ? 'bold' : ''}`}>Save 32%</span> w/ subscription</span> : <span>✔ <span className={`${selectedPlan === 2 ? 'bold' : ''}`}>Save 29%</span> (or $7.5)</span>}</li>
              <li>✔ {isWeekly ? <span className={`${selectedPlan === 2 ? 'bold' : ''}`}>21 credits/week</span> : <span className={`${selectedPlan === 2 ? 'bold' : ''}`}>21 credits</span> }</li>
              {/* isWeekly ? <li>✔ <span className={`${selectedPlan === 2 ? 'bold' : ''}`}>7 days free!</span></li> : ''*/}
            </ul>
          </button>
        </div>
        
        <div className="billing-toggle">
          <span className="slider-text">Pay-per-use</span>
          <label className="switch">
            <input type="checkbox" checked={isWeekly} onChange={handleBillingToggle} />
            <span className="slider round"></span>
          </label>
          <span className="slider-text">Subscribe & Save</span>
        </div>
        <div className="popup-footer">
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="confirm-button" onClick={() => handleConfirm()}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePopup;
