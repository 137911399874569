import React from 'react';
import './InsightsSection.css';
import insightsImg1 from '../assets/images/insights-img1.png'; // Adjust the path as needed
import insightsImg2 from '../assets/images/insights-img2.png'; // Adjust the path as needed

const InsightsSection = () => {
  return (
    <div id="home" className="insights-section">
      <div className="content-container">
        {/* Left Column - Images */}
        <div className="images-column">
          <div className="images-row">
            <div className="image-container">
              <img src={insightsImg1} alt="Insight 1" loading="lazy" />
            </div>
            <div className="image-container">
              <img src={insightsImg2} alt="Insight 2" loading="lazy" />
            </div>
          </div>
        </div>

        {/* Right Column - Text */}
        <div className="text-column">
          <h3>
            <span>Actionable Insights in<br /></span>
            <span>simple, intuitive format</span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default InsightsSection;
