import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import './PredictionOutput.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FiArrowUp, FiMinus, FiArrowDown } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'; // Import the spinner

const PredictionOutput = ({ gptOutput, featureImportance, positiveFeatures, negativeFeatures, ticker }) => {
  const [graphData, setGraphData] = useState([]);
  const [redditPosts, setRedditPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newsText, setNewsText] = useState(''); // State to hold extracted news text
  const [firstPart, setFirstPart] = useState('');
  const [secondPart, setSecondPart] = useState('');
  const [remainingPart, setRemainingPart] = useState('');
  const [activeSection, setActiveSection] = useState('Data');
  const [technicalPart, setTechnicalPart] = useState('');
  const [dataAnalysisPart, setDataAnalysisPart] = useState('');
  const [technicalAnalysisPart, setTechnicalAnalysisPart] = useState('');
  const [redditLoading, setRedditLoading] = useState(true); // State for Reddit loading


  useEffect(() => {
    if (!ticker) return;

    let isCurrent = true; // Flag to track the current request
    setGraphData([]); // Reset graphData when ticker changes
    setError(null); // Clear any previous errors
    setLoading(true);

    const fetchData = async () => {
      try {
        const baseURL =
          process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV'
            ? 'http://127.0.0.1:5000'
            : 'https://flask-backend-52245432644.us-central1.run.app';

        const response = await axios.get(`${baseURL}/actuals-data`, {
          params: { ticker },
        });

        if (!isCurrent) return; // Ignore if this is not the latest request

        const result = response.data.data;
        if (result && result.length > 0) {
          const formattedData = result.map((row) => ({
            Date: row.date,
            Prediction_Value: Number(row.prediction_value),
            Close: Number(row.close),
          }));
          setGraphData(formattedData);
        } else {
          setError('No model fit information available yet');
        }
      } catch (err) {
        if (isCurrent) setError('Failed to load data');
      } finally {
        if (isCurrent) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isCurrent = false; // Cancel this request if the component unmounts or ticker changes
    };
  }, [ticker]);

  const fetchRedditData = async (ticker) => {
    const baseURL =
      process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV'
        ? 'http://127.0.0.1:5000'
        : 'https://flask-backend-52245432644.us-central1.run.app';
  
    try {
      const response = await axios.post(`${baseURL}/fetch-reddit-posts`, {
        ticker: ticker,
        keywords: ["buy", "sell", "trade", "stock", "investment", "shares"],
        limit:25,
      });
  
      if (response.data && response.data.reddit_posts) {
        console.log("Fetched Reddit Data:", response.data.reddit_posts);
        return response.data.reddit_posts;
      } else {
        console.error("No Reddit posts found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching Reddit posts:", error);
      return [];
    }
  };

  useEffect(() => {
    if (!ticker) return;

    const getRedditPosts = async () => {
      setRedditLoading(true); // Set loading state for Reddit data
      const redditData = await fetchRedditData(ticker);
      setRedditPosts(redditData);
      setRedditLoading(false); // Set loading state to false once data is fetched
    };
    getRedditPosts();
  }, [ticker]);

  
  // # Frontend modifications
const formatTimeDifference = (timestamp) => {
    if (!timestamp) return 'No recent comments';
    
    const now = new Date();
    const date = new Date(timestamp * 1000);
    const diffSeconds = Math.floor((now - date) / 1000);
    
    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60
    };
    
    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const interval = Math.floor(diffSeconds / secondsInUnit);
        if (interval >= 1) {
            return `${interval} ${unit}${interval === 1 ? '' : 's'} ago`;
        }
    }
    
    return 'Just now';
};

  const formatValue = (value) => {
    return (typeof value === 'number' && !isNaN(value)) ? `$${value.toFixed(2)}` : 'N/A';
  };

  const splitGptOutput = (gptOutput) => {
    // Sanitize and parse the HTML content
    const sanitizedOutput = DOMPurify.sanitize(gptOutput);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedOutput, 'text/html');
  
    // Locate paragraphs and filter based on the presence of the "News" keyword
    const paragraphs = Array.from(doc.querySelectorAll('p'));
    let newsStartIndex = -1;
    let newsEndIndex = -1;
  
    paragraphs.forEach((p, index) => {
      if (p.textContent.trim().toLowerCase().includes("news:")) {
        newsStartIndex = index;
      }
      // Check if "Insider trading" or similar marker indicates the end of the news section
      if (newsStartIndex !== -1 && p.textContent.trim().toLowerCase().includes("insider trading")) {
        newsEndIndex = index;
      }
    });
  
    if (newsStartIndex !== -1) {
      const newsContent = paragraphs.slice(newsStartIndex, newsEndIndex !== -1 ? newsEndIndex : undefined)
        .map(p => p.textContent.trim().replace(/^news:\s*/i, ''))
        .join(' ');
  
      setNewsText(newsContent);
    } else {
      setNewsText("No specific news section found.");
    }
  
    // Extract text before the "News" section
    const firstPart = paragraphs.slice(0, newsStartIndex).map(p => p.outerHTML).join("");
    setFirstPart(firstPart);
  
    // Extract text after the "News" section
    const remainingPart = newsEndIndex !== -1 ? paragraphs.slice(newsEndIndex).map(p => p.outerHTML).join("") : '';
    setRemainingPart(remainingPart);

    const { dataAnalysisPart, technicalAnalysisPart } = splitFirstPart(firstPart);
    setDataAnalysisPart(dataAnalysisPart);
    setTechnicalAnalysisPart(technicalAnalysisPart);
  };

  const splitFirstPart = (firstPart) => {
    // Sanitize and parse the HTML content of firstPart
    const sanitizedFirstPart = DOMPurify.sanitize(firstPart);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedFirstPart, 'text/html');
  
    // Locate paragraphs within the firstPart
    const paragraphs = Array.from(doc.querySelectorAll('p'));
    let technicalStartIndex = -1;
  
    // Identify where "What does technical analysis mean?" starts
    paragraphs.forEach((p, index) => {
      if (p.textContent.trim().toLowerCase().includes("what does technical analysis mean")) {
        technicalStartIndex = index;
      }
    });
  
    // Split the content based on the identified technical analysis section
    const dataAnalysisPart = paragraphs.slice(0, technicalStartIndex).map(p => p.outerHTML).join("");
    const technicalAnalysisPart = technicalStartIndex !== -1 
      ? paragraphs.slice(technicalStartIndex).map(p => p.outerHTML).join("")
      : "";
  
    return { dataAnalysisPart, technicalAnalysisPart };
  };
  
  
  useEffect(() => {
    splitGptOutput(gptOutput); // Extract news text and split gptOutput
  }, [gptOutput]);


  useEffect(() => {
    console.log('redditPosts:', redditPosts);
  }, [redditPosts]); // Run whenever redditPosts changes
  
  const topRedditPosts = Array.isArray(redditPosts)
  ? redditPosts
      .sort((a, b) => b.created_utc - a.created_utc) // Sort by most recent (timestamp)
      .slice(0, 5) // Limit to top 5 posts
  : [];

  
        return (
          <div className='output'>
          <div className="prediction-text">
          <div
            className="gpt-output-contents"
            dangerouslySetInnerHTML={{ __html: dataAnalysisPart }}
          />
          </div>

      <div className="prediction-output-container">
      <div className='second-tab'>
      <div className='quant-left-column'>
      
      {/* Graph container */}
{!loading && !error && graphData.length > 0 && (
  <div className="predictiion-graph-container">
    <div className="graph-container">
      <h3>AI-Model Fit: Predicted v. Actual</h3>
      <ResponsiveContainer>
        <LineChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Date"
            textAnchor="end"
            tickFormatter={(date) => {
              const dateObj = new Date(date);
              if (isNaN(dateObj.getTime())) return '';
              const month = String(dateObj.getMonth() + 1).padStart(2, '0');
              const day = String(dateObj.getDate()).padStart(2, '0');
              return `${month}/${day}`;
            }}
          />
          <YAxis domain={['auto', 'auto']} tickFormatter={(value) => `$${Math.round(value)}`} />
          <Tooltip formatter={(value, name) => [formatValue(value), name]} />
          <Legend />
          <Line
            type="monotone"
            dataKey="Prediction_Value"
            stroke="#1D3557"
            name="Prediction"
            strokeWidth={3}
            dot={false}
            strokeDasharray="5 5"
          />
          <Line
            type="monotone"
            dataKey="Close"
            stroke="#139C8D"
            name="Actual"
            strokeWidth={3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
)}
      <div className='technical-container'>
      {/* Technical context container */}
         <div className="prediction-text-container">
          <div
            className="gpt-output-content"
            dangerouslySetInnerHTML={{ __html: technicalAnalysisPart }}
          />
          </div>
          {/* Influences container */}
          <div className="influences-container">
          <div className="influence-box influence-positive">
            <div className="icon">+</div>
            <div className="influence-text">
              <h3>Positive Influences</h3>
              <ul>
                {positiveFeatures.map((feature, index) => (
                  <li key={index}>
                    {feature}: +{featureImportance.find(f => f.Feature === feature)?.Importance?.toFixed(3) || 'N/A'}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="influence-box influence-negative">
            <div className="icon">-</div>
            <div className="influence-text">
              <h3>Negative Influences</h3>
              <ul>
                {negativeFeatures.map((feature, index) => (
                  <li key={index}>
                    {feature}: -{featureImportance.find(f => f.Feature === feature)?.Importance?.toFixed(3) || 'N/A'}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        </div>
        </div>
      

  <div className='qual-right-column'>
    {/* Reddit container */}
  <div className="reddit-posts-container">
  <div className="reddit-header">
    <h3>
      <FontAwesomeIcon icon={faReddit} className="reddit-logo" style={{ color: '#FF4500' }} />
      Recent Reddit Discussions
    </h3>
  </div>
  {redditLoading ? (
          <div className="loading-icon">
          <ClipLoader color="#FF4500" size={50} />
          <span className="loading-text">Loading latest posts for {ticker}</span>
        </div>
        ) : (
  <ul>
    {topRedditPosts.map((post, index) => (
      <li key={index} className="reddit-post">
        <div className="reddit-post-header">
          <img
            src={post.author_profile_pic || 'default-profile-pic-url'} 
            alt="Author Logo"
            className="author-logo"
          />
          <a href={post.url} target="_blank" rel="noopener noreferrer" className="post-title">
            {post.title}
          </a>
          <div className="oldest-comment-time">
        <span>{post.latest_comment_time || "N/A"}</span>
      </div>
        </div>
        <div className="reddit-post-meta">
          <span className="meta-item">
            <FiArrowUp style={{ color: '#28a745' }} />
          </span>
          <span className="meta-item">{post.score}</span>
          <span className="meta-item">
            <FiArrowDown style={{ color: '#dc3545' }} />
          </span>
          <span className="meta-item">💬 {post.comments_count}</span>
        </div>
      </li>
    ))}
  </ul>
  )}
</div>

   {/* News Section */}
  <div className="news-section">
        <h3>Trending News</h3>
        <table className="news-table">
        <tbody>
            <tr>
              <td className="news-icon-cell"><FiArrowUp /></td>
              <td>{newsText}</td>
            </tr>
              <tr>
                <td className="news-icon-cell"><FiMinus /></td>
                <td></td>
              </tr>
              <tr>
                <td className="news-icon-cell"><FiArrowDown /></td>
                <td></td>
              </tr>
          
        </tbody>
      </table>
    </div>


  {/* Insider Trading */}
    <div className="prediction-text-container">
        <div
          className="gpt-output-content"
          dangerouslySetInnerHTML={{ __html: remainingPart }}
        />
        </div>
</div>
</div>
</div>
</div>
);
};

// return (
//   <div className="prediction-output-container">
//     <nav className="navigation-bar">
//   {['Social'].map((section) => (
//     <button
//       key={section}
//       className={`nav-link ${activeSection === section ? 'active' : ''}`}
//       onClick={() => setActiveSection(section)}
//     >
//       {section === 'Social' ? (
//         <FontAwesomeIcon icon={faReddit} className="reddit-icon" style={{ color: '#FF4500', fontSize:'20px'}} />
//       ) : (
//         section
//       )}
//     </button>
//   ))}
// </nav>
//     <div className="section-content">{renderSectionContent()}</div>
//   </div>
// );
// };



export default PredictionOutput;